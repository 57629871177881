<template>
  <div style="background-color: white;">
    <div class="content_area" v-loading="loading">
      <div class="title">
        <div class="titleText">整箱运价详情</div>
        <div>
          <el-button
            v-if="USER_INFO&&USER_INFO.user_type==2"
            class="booking"
            type="success"
            size="small"
            @click="showDialog"
            >一键复制</el-button
          >
          <el-button
            class="booking"
            type="success"
            size="small"
            @click="mackInquiry"
            :loading="loading"
            >生成报价</el-button
          >
          <el-button
            type="warning"
            size="small"
            v-if="USER_INFO.user_type == 2"
            @click="consultingFunc"
            >新建舱位咨询</el-button
          >
        </div>
      </div>
      
      <div class="mainbox">
        <shipMsg :header="header"></shipMsg>
        <div class="cargoInfo">
          <div class="cost_detail">
            <div v-for="(item,i) in  options" :key='i'  v-if="item.reject==0">
              <h1>{{item.name+'成本价'}}</h1>
              <div >
                <span class="code">{{ item.code }}</span>
                <span class="num">{{ item.sell }}</span>
              </div>
            </div>
          </div>

          <div class="customBox">
            <p class="c_tit">分享客户<small>( 仅可选择已关联的客户，分享客户必须选择 )</small></p>
            <div class="c_box">
                <div>
                  <span>客户</span>
                  <el-select size="mini" v-model="client_id" filterable @change="customChange" placeholder="">
                    <el-option
                      v-for="item in customArr"
                      :key="item.value"
                      :label="item.name_zh"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </div>
                <div>
                  <span>客户公司</span>
                  <el-input size="mini" style="width:230px" disabled :placeholder="client_id?custom_company||'-':'通过客户自动获取，不可编辑'"></el-input>
                </div>
            </div>
          </div>

          <div class="seaMoney">
            <p class="seaTitle">费用明细<span style="font-size:14px;font-weight: 400;color: #909399;line-height: 40px;">（报价时以下列的费用为准）</span></p>
            <div class="searchBox">
              请选择箱型：
              <el-checkbox-group @change="change" :min="1" v-model="checkedArr" text-color='#67C23A'>
                <div  style="display:inline-block;margin-right:20px" v-for="(item, index) in options"  :key="index" >
                    <el-checkbox
                      class="myRedCheckBox"
                      text-color='#67C23A'
                    v-if="item.reject==0"
                      :label="item.name"
                    >
                      {{ item.name }}
                    </el-checkbox>
                </div>
              </el-checkbox-group>
              <span style="color: #ff7727; padding-left: 50px"
                >至少需选择一种箱型，将以选择的箱型进行报价</span
              >
            </div>
            <div class="unno" style="margin-top: 20px" v-if="$route.query.unno">
              <div>
                UNNO
                <el-input v-model="unno" placeholder="" size="small" style="width:200px"></el-input>
                <el-button
                  type="success"
                  size="small"
                  class="booking"
                  style="margin-left: 20px"
                  @click="recalculate"
                  >重新计算</el-button
                >
                <span style="color: #ff7727; padding-left: 50px"
                  >更改UNNO后需重新计算海运费</span
                >
              </div>
              <div class="unnoMsgBox">
                <div class="psn">PSN： {{ header.pscn }}</div>
                <div class="class">Class： {{ header.classify }}</div>
                <div>PG： {{ header.packing_group }}</div>
              </div>
            </div>
          </div>

          <div class="moneyList">
            <div class="seaItem">
              <div class="itemTitleBox">
                <div>
                  <img src="https://ym-attached-file.oss-cn-shanghai.aliyuncs.com/miniProgram/ship2.png" style="width:30px;height:30px;margin-right:10px" alt="">
                  <span class="itemTitle">海运费：</span>
                  <span class="itemMOney">
                    {{ header.currency_code }}<span class="price" > {{ parseInt(seaTotal) }}</span>
                  </span>
                </div>
              </div>
              <div class="itemContent">
                <div class="tableLine tableTitle">
                  <div class="table1"></div>
                  <div class="table2">币种</div>
                  <div class="table3">计量单位</div>
                  <div class="table4">单价</div>
                </div>
                <div
                  class="tableLine tableLine_edit"
                  v-for="(item, index) in options"
                  :key="index"
                  v-if="item.checked"
                >
                  <div  v-if="item.checked">
                      <div class="table1" style="display:flex;align-items: center;">海运费</div>
                      <div class="table2" style="display:flex;align-items: center;">{{ header.currency_code }}</div>
                      <div class="table3" style="display:flex;align-items: center;">{{ item.name }}</div>
                      <div
                        class="table4 price"
                        style="display:flex;align-items: center;"
                      >
                        {{ item.price }}
                        <i class="el-icon-edit cursor" v-if="options[index].price!=0" @click="handle_cost_item(item,index)"></i>
                        <div  v-if="item.is_edit==true" style="display:flex;padding-left:10px">
                          <el-input
                            style="width:180px"
                            placeholder="请输溢价金额（整数）"
                            type='number'
                            size="mini"
                            :min="0"
                            v-model="options[index]._price"
                            clearable>
                          </el-input>
                          <el-button style="margin-left:10px" @click="handle_cost_sure(item,index)"  type="primary" size="mini">确定</el-button>
                          <el-button size="mini"  @click="handle_cost_cancle(item,index)" >取消</el-button>
                        </div>
                      </div>
                      
                  </div>
                </div>
              </div>
            </div>
            <costComp
              :costList='costList'
              :edit='true'
              @open_close="open_close"
              @cost_item_del="cost_item_del"
              @addCost="addCost"
            ></costComp>
          
            <div class="page_tips">
              <i class="el-icon-warning"></i>
              <span>订舱前请确认好准确的数据，订舱后因数据不对或其他任何原因退关都会产生空舱费。</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <costAdd ref="c_costAdd" @ret_cost="ret_cost" ></costAdd>
    <el-dialog
        title="选择需要复制的内容"
        :visible.sync="copy_dialog"
        width="400px"
        :before-close="handleClose"
      >
      <div>


        <div class="chooseItem">
            <div class="itemtitle">基本信息</div>
            <div class="handleBox">
              <el-switch class="switch" v-model="check_base" active-color="#91be42" inactive-color="#ccc" ></el-switch>
            </div>
        </div>
        <div
          class="chooseItem"
          v-for="(item, index) in costList"
          :key="index"
        >
          <div class="itemtitle">{{ item.group_name }}</div>
          <div class="handleBox">
            <el-switch
              class="switch"
              v-model="item.canCopy"
              active-color="#91be42"
              inactive-color="#ccc"
            >
            </el-switch>
          </div>
        </div>

        <div class="chooseItem">
              <div class="itemtitle">订舱提示</div>
              <div class="handleBox">
                <el-switch class="switch" v-model="check_tips" active-color="#91be42" inactive-color="#ccc" ></el-switch>
              </div>
          </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose" size="small"
          >取 消</el-button
        >
        <el-button type="primary" class="booking" size="small" @click="copy"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import costAdd from "~bac/components/ymp/costAdd";
import shipMsg from "~bac/components/ymp/shipMsg2side";
import costComp from "~bac/components/ymp/costComp";
import { secondFormat } from "~/basePlugins/filters"
export default {
  name: "freightSearchDetailsCFS",
  components:{
    costAdd,
    shipMsg,
    costComp
  },
  data() {
    return {
      check_base:true,
      check_tips:true,
      copy_dialog:false,
      del_image:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/ymp/del_icon.png",
      prop_unno:"",
      cankao_arr:[],
      unnoOptions: [],
      options: [
        {
          checked: false,
          name: "20GP",
        },
        {
          checked: false,
          name: "40GP",
        },
        {
          checked: false,
          name: "40HQ",
        },
        // {
        //   checked: false,
        //   name: "45HQ",
        // },
      ],
      checkedArr: [],
      header: {},
      loading: false,
      costList: [],
      value: false,
      volume: 0,
      weight: 1000,
      number: 1,
      seaTotal: 0,
      unno: "",
      user_type: 1,
      unnoMsg: {},
      yijia_percent:0,
      customArr:[],
      client_id:"",
      custom_company:""
    };
  },
  mounted() {
    this.user_type = this.USER_INFO ? this.USER_INFO.user_type : 1;
    if (this.$route.query.unno) {
      this.unno = this.$route.query.unno;
      this.prop_unno = this.$route.query.unno;
    }
    this.$log({
      type: 2,
      topic: "ymp.inquiry",
      id: "ymp.inquiry.fcl.detail",
      content: JSON.stringify({
        id: this.$route.query.id || '',
      }),
    });
    this.getHeader();
    // this.get_yijia() 弃用
    this.getCustomList()
  },
  computed: {},
  methods: {
    customChange(e){
      let item = this.customArr.find( el=>el.id==e)
      if(item){
        this.custom_company=item.company_name_zh
      }else{
        this.custom_company=''
      }
    },
    async getCustomList(){
       let params = {
        user_id:this.USER_INFO.id,
        nopage:1,
      };
      let result = await this.$store.dispatch( //
        "API_freight/cooperative_client_list",
        params
      );
      this.customArr=result.data
    },
    // async get_yijia(){
    //   let res=await this.$store.dispatch("API_freight/premium_percentage",{})
    //   this.yijia_percent=res.data/100
    // },
    //  费用项目 edit
    handle_cost_item(item,i){
      this.options[i].is_edit = !this.options[i].is_edit
      this.options=JSON.parse(JSON.stringify(this.options))
      // this.options[i]._price = parseInt(Number(this.options[i].price*this.yijia_percent))
      this.options[i]._price =null
    },
    //  费用项目 取消
    handle_cost_cancle(item,i){
      this.options[i].is_edit = !this.options[i].is_edit
      this.options[i]._price = this.options[i].price
    },
    //  费用项目 确定
    handle_cost_sure(item,i){
      // this.options[i].price = parseInt(Number(this.options[i]._price))
      if(this.options[i]._price!=0){
        this.options[i].price =Number(this.options[i]._price*1+this.options[i].sell*1).toFixed()
        this.componentSea()
        this.effect_cost()
      }else{
         this.options[i].price =Number(this.options[i].sell).toFixed()
         this.componentSea()
          this.effect_cost()
      }
      this.options[i].is_edit = !this.options[i].is_edit
    },
    // 我要询价 提交请求
    async to_inquery(){
        if(this.customArr.length==0){
          this.$message.warning("请先去绑定客户")
          return
        }
        if(this.client_id==''){
          this.$message.warning("请选择分享客户")
          return
        }

        let obj={
        user_id:this.USER_INFO.id,
        inquiry_type:1,//1-整箱，2-拼箱
        freight_id:this.header.id,//运价id
        cargo_name:"",//货物名称
        is_dangerous:this.$route.query.is_dangerous,//是否危险品：0-否，1-是
        un_no:this.prop_unno,//危险品编号
        classify:this.header.classify||null,//危险品类别,
        surcharge:JSON.stringify(this.costList),//扩展信息；json类型,
        //箱体信息；json类型,
        sea_freight:JSON.stringify([
            {
              name:this.options[0].name,
              number:this.options[0].checked?1:0,
              code:this.header.currency_code,
              price:this.options[0].price,
              sell:this.options[0].sell,
              reject:this.header.reject_20gp
            },
            {
              name:this.options[1].name,
              number:this.options[1].checked?1:0,
              code:this.header.currency_code,
              price:this.options[1].price,
              sell:this.options[1].sell,
              reject:this.header.reject_40gp
            },
            {
              name:this.options[2].name,
              number:this.options[2].checked?1:0,
              code:this.header.currency_code,
              price:this.options[2].price,
              sell:this.options[2].sell,
              reject:this.header.reject_40hc
            },
          ]),
          
        is_quote:1,//是否已报价：0-否，1-是（销售主动报价传 1）
        sales_id:this.USER_INFO.id,//报价销售ID,
        client_id:this.client_id
        // from_freight:header,//起运港费用,
        // dest_freight:"",//目的港费用,
        // remarks:header.remarks,//备注信息,
        // total_freight:"",//总价，json格式,
      }
      let res=await this.$store.dispatch("API_freight/offer_save",obj)
      this.$router.push({
        path:"/share",
        query:{
          id: res.data
        }
      })
    },
    consultingFunc(){
      this.$router.push({
        path: '/consulting',
        query: {
          id: this.$route.query.id,
          unno: this.unno
        }
      })
    },
    // 生成报价
    async mackInquiry(){
     if (!this.IS_LOGIN) {
        return this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN", {
          waitTodo: () => {
            this.to_inquery()
          },
        });
      } else {
        this.to_inquery()
      }


    },
    async inquire(){
      let params={
        user_id:this.USER_INFO.id,
        inquiry_type:1,
        freight_id:this.$route.query.id,
        is_dangerous:this.unno?1:0,
        un_no:this.prop_unno,
        container:JSON.stringify([
          {
            name:this.options[0].name,
            number:this.options[0].checked?1:0,
            code:this.header.currency_code,
            price:this.options[0].price
          },
           {
            name:this.options[1].name,
            number:this.options[1].checked?1:0,
            code:this.header.currency_code,
            price:this.options[1].price
          },
           {
            name:this.options[2].name,
            number:this.options[2].checked?1:0,
            code:this.header.currency_code,
            price:this.options[2].price
          },
        ]),
        extend:JSON.stringify(this.costList)
      }
      let data = await this.$store.dispatch("API_freight/mackInquire", params);
      if(data.success){
        this.$confirm('询价成功，是否前往我的报价?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$router.push({
          path: "/console/myInquire/list",
          query: {},
        });
        }).catch(() => {

        });
      }
    },
    // 子组件  -打开关闭
    open_close(e){
      this.costList[e.key].open=!this.costList[e.key].open
      this.costList=JSON.parse(JSON.stringify(this.costList))
    },
    // 子组件 --添加费用
    addCost(e){
      this.$refs.c_costAdd.openDialog(e.index)
    },
    ret_cost(e){ // 添加费用 的 事件函数
      this.costList[e.index].data_list.push(e.obj)
      // this.computed_price_add(e.index,e.obj)
      this.effect_cost()
    },
    // 子组件 --删除费用
    cost_item_del(e){
      // this.computed_price_del(e.index,e.obj)
      this.costList[e.index].data_list.splice(e.i,1)
      this.effect_cost()
    },
    // 计算附加费的价格--添加
    computed_price_add(index,obj){
      let totalPrice = this.costList[index].group_total_price
      if(obj.unit == 5||obj.measure=="GP"||obj.measure=="箱"){ // 箱
        for(var key of this.checkedArr){
          if(key=='20GP'){
              if(totalPrice[obj.code]){
                totalPrice[obj.code]+=Number(obj.GP20)
              }else{
                totalPrice[obj.code]=Number(obj.GP20)
              }
          }
          if(key=='40GP'){
              if(totalPrice[obj.code]){
                totalPrice[obj.code]+=Number(obj.GP40)
              }else{
                totalPrice[obj.code]=Number(obj.GP40)
              }
          }
          if(key=="40HQ"){
              if(totalPrice[obj.code]){
                totalPrice[obj.code]+=Number(obj.HC40)
              }else{
                totalPrice[obj.code]=Number(obj.HC40)
              }
          }
        }
      }else{             // 票 ok
        if(totalPrice[obj.code]){
          totalPrice[obj.code]+=Number(obj.price)
        }else{
          totalPrice[obj.code]=Number(obj.price)
        }
      }
      this.costList[index].group_total_price = totalPrice
      this.costList=JSON.parse(JSON.stringify(this.costList))
      this.$forceUpdate();
    },
    // // 计算附加费的价格--删除
    computed_price_del(index,obj){
      let totalPrice = this.costList[index].group_total_price

      if(obj.unit == 5||obj.measure=="GP"||obj.measure=="箱"){ // 箱
        for(var key of this.checkedArr){
          if(key=='20GP'){
             totalPrice[obj.code]-=Number(obj.GP20)
          }
          if(key=='40GP'){
              totalPrice[obj.code]-=Number(obj.GP40)
          }
          if(key=="40HQ"){
              totalPrice[obj.code]-=Number(obj.HC40)
          }
        }
      }else{             // 票 ok
        totalPrice[obj.code]-=Number(obj.price)
      }
      this.costList[index].group_total_price = totalPrice
      this.$forceUpdate();
    },
    openList(item) {
      if (!this.IS_LOGIN) {
        return this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN", {
          waitTodo: () => {
            item.open = true;
          },
        });
      } else {
        item.open = true;
      }
    },
    change(e) {
      this.options.forEach((res) => {
        if (JSON.stringify(e).indexOf(res.name) > -1) {
          res.checked = true;
        } else {
          res.checked = false;
        }
      });
      if(this.$route.query.unno){
         this.recalculate() // 切换箱型时检查 危险品信息
      }else{
        this.componentSea();
      }

      this.effect_cost();//选择箱型===>计算附加费
    },
    // 选择箱型===>影响附加费
    effect_cost(){
      let arr=[]
      for(var item of this.checkedArr){
        for( var dd of this.options ){
          if(item==dd.name&&dd.price!=0){
            arr.push(item)
          }
        }
      }
      for(var item of this.costList){
        item.group_total_price={}
        for(var it of item.data_list){
          if(it.unit == 5||it.measure=="GP"||it.measure=="箱"){ //---------------------------------------------箱
            for(var key of arr){
              if(key=='20GP'){
                if(item.group_total_price[it.code]){
                  item.group_total_price[it.code]+=Number(it.GP20)
                }else{
                  item.group_total_price[it.code]=Number(it.GP20)
                }
              }
              if(key=='40GP'){
                if(item.group_total_price[it.code]){
                  item.group_total_price[it.code]+=Number(it.GP40)
                }else{
                  item.group_total_price[it.code]=Number(it.GP40)
                }
              }
              if(key=="40HQ"){
                  if(item.group_total_price[it.code]){
                    item.group_total_price[it.code]+=Number(it.HC40)
                  }else{
                    item.group_total_price[it.code]=Number(it.HC40)
                  }
              }
            }
          }else if(it.code==""){  // 没有币种得不计算

          }else{  // 票
            if(item.group_total_price[it.code]){
              item.group_total_price[it.code]+=Number(it.price)
            }else{
              item.group_total_price[it.code]=Number(it.price)
            }
          }
        }
      }
      this.costList=JSON.parse(JSON.stringify(this.costList))
    },
    async getHeader() {
      this.loading = true;
      let params = {
        freight_id: this.$route.query.id,
        user_type: this.USER_INFO ? this.USER_INFO.user_type : 1,
        unno: this.unno,
        dangerous_status: this.unno ? 1 : 0,
      };

      let result = await Promise.all([
        this.$store.dispatch("API_freight/getFclInfoDetail", params),

      ]);
      this.loading = false;
      if (result[0].success) {
        let data = result[0].data;
        data.week = [];
        data.abs = Math.abs(data.price_lcl_dangerous);
        data.shipping_date_arr = data.shipping_date.split(",");
        data.shipping_date_arr.forEach((ee) => {
          if (ee == 1) {
            data.week.push("周一");
          }
          if (ee == 2) {
            data.week.push("周二");
          }
          if (ee == 3) {
            data.week.push("周三");
          }
          if (ee == 4) {
            data.week.push("周四");
          }
          if (ee == 5) {
            data.week.push("周五");
          }
          if (ee == 6) {
            data.week.push("周六");
          }
          if (ee == 7) {
            data.week.push("周日");
          }
        });
        // 后台用身份去计算价格
        this.options[0].price = data.price_20gp.toFixed();
        this.options[0].sell = data.price_20gp.toFixed(); 
        // this.options[0].sell = data.price_20gp_of_sell.toFixed();
        this.options[0].reject = data.reject_20gp.toFixed();

        this.options[1].price = data.price_40gp.toFixed();
        this.options[1].sell = data.price_40gp.toFixed();
        // this.options[1].sell = data.price_40gp_of_sell.toFixed();
        this.options[1].reject = data.reject_40gp.toFixed();

        this.options[2].price = data.price_40hc.toFixed();
        this.options[2].sell = data.price_40hc.toFixed();
        // this.options[2].sell = data.price_40hc_of_sell.toFixed();
        this.options[2].reject = data.reject_40hc.toFixed();

        this.options.map(item=>{
          item._price=item.price
          item.is_edit=false
          item.code=data.currency_code
          return item
        })
        let check_item=this.options.find(item=>{
          return item.reject==0
        })
        this.options.map(item=>{
          if(item.name==check_item.name){
            item.checked=true
          }
          return item
        })

        if(this.checkedArr.length==0&&check_item){
          this.checkedArr.push(check_item.name)
        }

        this.seaTotal = data.price_20gp;
        this.volume = data.mini_cargo;
        this.header = data;
        this.cankao_arr=[
          {
            lable:""
          },
        ]
        this.componentSea();
      }
      this.getCost()
    },
    async getCost(){

      let params2 = {
        cargo_type: this.unno ? 1 : 2,
        spell_type: 1,
        shipping_line: this.$route.query.shipping_line_id,
        freight_id: this.$route.query.id,
        // volume: this.volume,
        // weight: this.weight,
        // piece_num: this.piece_num,
        eq_types: this.options
          .filter((res) => {
            return res.checked;
          })
          .map((res) => {
            return res.name;
          })
          .join(","),
      };
      let res=await this.$store.dispatch("API_freight/getnewFreightInfo", params2)
      if (res.success) {
        res.data.forEach((ee, index) => {
          ee.canCopy = true;
          ee.open = true;
          if (index > 0) {
            ee.open = false;
          }
        });
        this.costList = res.data;
      }
    },
    async getHeader_unno(){
      this.loading = true;
      let params = {
        freight_id: this.$route.query.id,
        user_type: this.USER_INFO ? this.USER_INFO.user_type : 1,
        unno: this.unno,
        dangerous_status: this.unno ? 1 : 0,
      };
      let res=await this.$store.dispatch("API_freight/getFclInfoDetail", params)
      this.header.pscn=res.data.pscn,
      this.header.classify=res.data.classify,
      this.header.packing_group=res.data.packing_group
      this.options[0].price = res.data.price_20gp.toFixed();
      this.options[0]._price = res.data.price_20gp.toFixed();
      this.options[0].sell = res.data.price_20gp.toFixed();
      // this.options[0].sell = res.data.price_20gp_of_sell.toFixed();
      this.options[1].price = res.data.price_40gp.toFixed();
      this.options[1]._price = res.data.price_40gp.toFixed();
      this.options[1].sell = res.data.price_40gp.toFixed();
      // this.options[1].sell = res.data.price_40gp_of_sell.toFixed();
      this.options[2].price = res.data.price_40hc.toFixed();
      this.options[2]._price = res.data.price_40hc.toFixed();
      this.options[2].sell = res.data.price_40hc.toFixed();
      // this.options[2].sell = res.data.price_40hc_of_sell.toFixed();
      this.componentSea();
      this.loading = false
    },
    async recalculate() {
      let res=await this.$store.dispatch("API_freight/check_unno",{unno:this.unno})
      if(res.success){
        this.prop_unno=this.unno
        this.getHeader_unno();
      }else{
        this.$message.warning("您输入的UNNO有误，UNNO和海运费不变")
        this.unno=this.prop_unno
        this.componentSea();
      }
    },
    componentSea() {
      let num = 0;
      this.options.forEach((res) => {
        if (res.checked) {
          num = num + res.price*1;
        }
      });
      this.seaTotal = num;
    },
    handleLogin() {
      this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
    },
    handleBookingSpace() {
      let info = {
        from_port_name_en: this.header.from_port_name_en,
        from_port_name_zh: this.header.from_port_name_zh,
        from_port: this.header.from_port,
        dest_port_name_en: this.header.dest_port_name_en,
        dest_port_name_zh: this.header.dest_port_name_zh,
        dest_port: this.header.dest_port,
      };
      this.$router.push({
        path: "/bookingSpace",
        query: {
          info,
          activeName: "first",
        },
      });
    },

    handleClose() {
      this.copy_dialog=false
    },
    showDialog() {
      this.$log({
        type: 1,
        topic: "ymp.inquiry",
        id: "ymp.inquiry.fcl.detail.copy",
        content: JSON.stringify({
          id: this.$route.query.id || '',
        }),
      });
      if (this.USER_INFO.company_bind_status != 0) {
        this.copy_dialog = true;
      } else {
        this.$router.push({
          path: "/console/company/basic",
          query: {},
        });
      }
    },
    copy() {
      var port =
        `${
          this.header.from_port || this.header.from_port_name_zh || "-"
        } - ${
          this.header.dest_port || this.header.dest_port_name_zh || "-"
        }` + "\n";
        if(this.check_base){
          if (this.header.trans_port) {
            port =
              port +
              `${
                this.header.trans_port ||
                this.header.trans_port ||
                "-"
              } `;
          }

          port = port + (this.header.trans_port ? "转 " : "直达 ");
          port = port + "\n";
          var sea_str='海运费'

          let container=[
            {
              name:this.options[0].name,
              number:this.options[0].checked?1:0,
              code:this.header.currency_code,
              price:this.options[0].price,
              sell:this.options[0].sell,
              reject:this.header.reject_20gp
            },
            {
              name:this.options[1].name,
              number:this.options[1].checked?1:0,
              code:this.header.currency_code,
              price:this.options[1].price,
              sell:this.options[1].sell,
              reject:this.header.reject_40gp
            },
            {
              name:this.options[2].name,
              number:this.options[2].checked?1:0,
              code:this.header.currency_code,
              price:this.options[2].price,
              sell:this.options[2].sell,
              reject:this.header.reject_40hc
            },
          ]

          for(var sea of  container){
            if(sea.number&&sea.price){
              sea_str+=(sea.code+' '+sea.price+'/'+sea.name+'  ')
            }
          }
          port=port+sea_str+"\n"
          
          port =
          port + `船公司：${this.header.name_zh}  ，船期：`;
          this.header.week.forEach((ee, vv) => {
            port = port + ee;
            if (vv < this.header.week.length - 1) {
              port = port + ",";
            }
          });
          port =port+`，航程：${this.header.days ? this.header.days : 0} 天，挂靠码头：${this.header.affiliated_terminal}，有效期：${ secondFormat(this.header.validity_start,'LL')}  -  ${ secondFormat(this.header.validity_end,'LL')}`
          port =port+'\n'+'备注：'+this.header.remarks + "\n"
        }
        
        let star ="";
        if (this.costList.length) {
          this.costList.forEach((res, index) => {
            if (res.data_list.length && res.canCopy) {
              // star = star + "\n";
              // star = star + "\n";
              star = star + "=========================";
              star = star + "\n";
              star = star + "【" + res.group_name + "】" + "\n";
              res.data_list.forEach((ee) => {
                if (ee.unit == 5||ee.measure=="GP"||ee.measure=="箱") {
                  star =
                    star +
                    `-- ${ee.name_zh || ee.name_en || "-"}：${Number(
                      ee.GP20
                    ).toFixed(2)} ${ee.code}/20GP,${Number(ee.GP40).toFixed(2)} ${
                      ee.code
                    }/40GP,${Number(ee.HC40).toFixed(2)} ${ee.code}/40HQ`;

                  // if (ee.lowest_price) {
                  //   star = star + `,MINI：${ee.lowest_price}`;
                  // }
                  if (ee.remarks) {
                    star = star + `,*${ee.remarks}`;
                  }
                  star = star + "\n";
                } else {
                  if((ee.price==0.01)&&ee.measure=='SET'){
                    star =star +
                    `-- ${ee.name_zh || ee.name_en || "-"}：`

                  }else if((ee.price==0.01)&&ee.measure!='SET'){
                    star =star +
                    `-- ${ee.name_zh || ee.name_en || "-"}：${ee.code}/${ee.measure} ,`;
                  }else{
                    star =star +
                    `-- ${ee.name_zh || ee.name_en || "-"}：${(ee.price==0.01)?0:Number(
                      ee.price
                    ).toFixed(2)} ${ee.code}/${ee.measure} ,`;
                  }

                  if (ee.lowest_price) {
                    star = star + `MINI:${ee.lowest_price}`;
                  }
                  if(ee.lowest_price && ee.remarks){
                    star = star + `,`
                  }
                  if (ee.remarks) {
                    star = star + `*${ee.remarks}`;
                  }
                  star = star + "\n";
                }
              });
              if(res.plan_remarks_list.length){
                star = star + "备注：";
                res.plan_remarks_list.forEach(element => {
                    star = star+element+'\n'
                });
              }

            }
          });
        }

        let lastMsg = ""
        if(this.check_tips){
          lastMsg = "\n" + "订舱前请确认好准确的数据，订舱后因数据不对或其他任何原因退关都会产生空舱费。\n可自主登录运抵达（www.yundida.com）查询危拼运价。";
        }
          
        var content = port.concat(star, lastMsg);

        // 使用textarea支持换行，使用input不支持换行
        const textarea = document.createElement("textarea");
        textarea.value = content;
        document.body.appendChild(textarea);

        textarea.select();
        if (document.execCommand("copy")) {
          document.execCommand("copy");
          this.$message({
            message: "您选择的费用信息已经复制到剪贴板",
            type: "success",
          });
          this.dialogVisible = false;
        }
        document.body.removeChild(textarea);
        this.handleClose() // 关闭弹框
    },
  },
};
</script>

<style scoped lang="less">
.cargoInfo{
  width: 1200px;
}
.cost_detail{
  background: #fff;
  height: 118px;
  padding: 19px 0;
  display: flex;
  >div{
    width: 25%;
    border-left:1px solid #DCDFE6;
    text-align: center;
    h1{
      padding-bottom: 10px;
      height: 33px;
      font-size: 18px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      line-height: 33px;
    }
    .code,.num{
      font-size: 18px;
      color: #FF7727;
    }
    .num{
      display: inline-block;
      padding-left: 5px;
      font-size: 24px;
    }
  }
  :first-child{
    border-left:0;
  }
}
.nodata{
  height: 100px;
  text-align: center;
  line-height: 100px;
  color: #606266;
}
.page_tips{
  width: 1300px;
  margin: auto;
  padding:26px 0;
  display: flex;
  align-items: center;
  i{
    color: #FAAD14;
    font-size:30px;
    padding-right:9px;
  }
  span{
    height: 24px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 24px;
  }
}
.unnoMsgBox {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 600px;
  .psn {
    width: 300px;
  }
  .class {
    width: 100px;
  }
  .PG {
    width: 100px;
  }
}
.unno {
  display: flex;
  align-items: center;
  padding-left:24px;
}
.mohu {
  color: transparent;
  -moz-user-select:none;//火狐
  -webkit-user-select:none;//webkit浏览器（Chrome，Opera，Safari等）
  -ms-user-select:none;//IE10
  -khtml-user-select:none;//早期浏览器
  user-select:none;
  text-shadow: #000 0 0 8px;
}
.moneyList {
  background: #fff;
  padding: 24px;
  .seaItem {
    margin-bottom: 30px;
    .itemTitleBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .btn {
        font-size: 16px;
        color: #999;
        cursor: pointer;
      }
    }
    .itemTitleBox {
      padding-bottom: 20px;
    }
    .el-icon-ship {
      display: inline-block;
      width: 30px;
      height: 30px;
      background: #ff7727;
      border-radius: 50%;
      text-align: center;
      line-height: 30px;
      font-size: 16px;
      color: #fff;
      margin-right: 10px;
    }
    .itemTitle {
      font-weight: 900;
      font-size: 18px;
    }
    .itemMOney {
      font-weight: 900;
      font-size: 18px;
    }
    .itemTips {
      font-size: 16px;
      color: #ff7727;
      padding-left: 10px;
    }
    .tableLine {
      display: flex;
      align-items: center;
      padding: 13px 0;
    }
    .tableLine {
      min-height: 60px;
      border-bottom:1px solid #EBEEF5;
      .table1 {
        width: 250px;
      }
      .table2 {
        width: 220px;
      }
      .table3 {
        width: 220px;
      }
      .table4 {
        width: 220px;
      }
      .table5 {
        width: 220px;
      }
      .table6 {
        padding: 0;
        max-height:40px;
        text-align: center;
        overflow:hidden;
        text-overflow:ellipsis;
        display:-webkit-box;
        -webkit-box-orient:vertical;
        -webkit-line-clamp:2;
      }
    }
    .tableTitle {
      background: #fbfcfa;
      border-bottom: 1px solid #ebeef5;
    }
  }
}
.seaMoney {
  background: #fff;
  padding:20px 0;
  margin: 10px 0;
  .seaTitle {
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;
    padding-left: 24px;
    font-size: 18px;
    font-weight: 900;
  }
  .searchBox {
    margin-top: 20px;
    padding-left: 24px;
    display: flex;
    align-items: center;
    .flex {
      display: flex;
      align-items: center;
    }
  }
}
.chooseItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .itemtitle {
    font-size: 14px;
  }

  .switch {
    height: 50px;
  }
}
.booking {
  background-color: #91be42;
  border-color: #91be42;
}
.content_area {
  background: #f3f6f8;
  min-height: 83vh;
  width: 1600px;
  .mainbox{
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
}
.title {
  display: flex;
  height: 58px;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  .titleText {
    font-weight: 800;
    color: #1f292e;
    font-size: 16px;
    padding-left: 10px;
  }
  .booking {
    background-color: #91be42;
    border-color: #91be42;
  }
}
.content {
  background: #f3f6f8;
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px 20px;
  justify-content: space-between;
  //min-height: 500px;
  .item {
    width: 49%;
    background: #ffffff;
    margin-top: 16px;
    .costTitle {
      font-weight: 800;
      color: #1f292e;
      font-size: 16px;
      padding: 20px;
    }
    .line {
      height: 1px;
      width: 100%;
      background: #dcdfe6;
    }
    .costContent {
      padding: 20px;
      .costItem {
        display: flex;
        justify-content: space-between;
        .costItemTitle {
          color: #1f292e;
        }
        .costItemContent {
          color: #f7a350;
          font-size: 18px;
          font-weight: 800;
          span {
            font-size: 14px;
            color: #1f292e;
            font-weight: 400;
            margin-left: 4px;
          }
        }
        .itRemarks {
          margin-top: 6px;
          color: #999999;
        }
        .code {
          font-size: 12px;
          color: #999999;
        }
      }
      .constItem2 {
        margin-top: 20px;
        color: #f46401;
      }
    }
  }
}
.goLogin {
  text-align: center;
  color: #298df8;
  width: 100%;
  padding: 20px 0;
}
.item2Text {
  padding-left: 20px;
  padding-bottom: 20px;
  margin-top: 10px;
  color: #333333;
}
.pageHint {
  margin-top: 10px;
  margin-left: 15px;
  padding: 0px 0 15px 15px;
  color: red;
}
.tableLine_edit>div{
  display: flex;
}
::v-deep .el-checkbox {
  .el-checkbox__label {
    color: #91be42;
  }

  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #91be42;
    border-color: #91be42;
  }

  .el-checkbox__input.is-focus .el-checkbox__inner,
  .el-checkbox__inner:hover {
    border-color: #91be42;
  }
}
.customBox{
  margin-top: 10px;
  background: #fff;
  padding:24px;
  .c_tit{
    font-size: 18px;
    font-weight: 900;
    small{
      display: inline-block;
      padding-left: 10px;
      font-size: 14px;
      font-weight: 400;
      color: rgb(144, 147, 153);
      line-height: 40px;
    }
  }
  .c_box{
    display: flex;
    padding-top: 20px;
    >div{
      padding-right: 40px;
      >span{
        display: inline-block;
        padding-right: 10px;
      }
    }
  }
}
</style>
